import React, { useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/Layout";
import { Helmet } from "react-helmet";
import {
  Col,
  Content,
  H2,
  Section,
  Line,
  H3,
  P,
  Arrow,
  Btn,
  Grid2,
  GridWrapper,
} from "../styles/shared";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Footer from "../shared/Footer";
import island from "../images/island.png";
import blue0 from "../images/BluCover.jpg";
import SMblue0 from "../images/SMBluCover.jpg";
import Menu from "../shared/Menu";
import redirect from "../shared/Redirect";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

//#region beach imgs
import beach1 from "../images/beach/beach1.jpg";
import beach2 from "../images/beach/beach2.jpg";
import beach3 from "../images/beach/beach3.jpg";
import beach4 from "../images/beach/beach4.jpg";
import beach5 from "../images/beach/beach5.jpg";
import beach6 from "../images/beach/beach6.jpg";
import beach7 from "../images/beach/beach7.jpg";
import beach8 from "../images/beach/beach8.jpg";
import beach9 from "../images/beach/beach9.jpg";
import beach10 from "../images/beach/beach10.jpg";
import beach11 from "../images/beach/beach11.jpg";
import beach12 from "../images/beach/beach12.jpg";
import beach13 from "../images/beach/beach13.jpg";
//#endregion

import blue40 from "../images/other/blu40.jpg";

const images = [
  {
    original: beach1,
    thumbnail: beach1,
  },
  {
    original: beach2,
    thumbnail: beach2,
  },
  {
    original: beach3,
    thumbnail: beach3,
  },
  {
    original: beach4,
    thumbnail: beach4,
  },
  {
    original: beach5,
    thumbnail: beach5,
  },
  {
    original: beach6,
    thumbnail: beach6,
  },
  {
    original: beach7,
    thumbnail: beach7,
  },
  {
    original: beach8,
    thumbnail: beach8,
  },
  {
    original: beach9,
    thumbnail: beach9,
  },
  {
    original: beach10,
    thumbnail: beach10,
  },
  {
    original: beach11,
    thumbnail: beach11,
  },
  {
    original: beach12,
    thumbnail: beach12,
  },
  {
    original: beach13,
    thumbnail: beach13,
  },
];

export default function IndexPage() {
  const [open, setOpen] = useState(false);
  const breakpoints = useBreakpoint();

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="An exclusive island location, rooted in nature. Sustainable, eco-living meets the ultimate beachside location in this secluded Hvar island bay, powered 100% by solar while still accommodating your every need."
        />
      </Helmet>
      {!open ? (
        <>
          <Header
            bg={blue0}
            sbg={SMblue0}
            type="index"
            setOpen={setOpen}
            overlay={true}
          />
          <Section>
            <Content>
              <Col align="end">
                <H2 align="right">
                  <span>WHAT'S IT LIKE?</span>
                  <Line align="end" />
                </H2>
                <H3 align="right">UNIQUE ; INSPIRED ; ECLECTIC</H3>
                <P color="rgb(123,116,110)" align="right">
                  Formerly a traditional fisherman's house, the recently
                  renovated villa now sleeps up to 8 people, with 4 comfortable
                  queen bedrooms and 3 bathrooms. The villa also features two
                  terraces from which to soak up the simply breathtaking
                  location. The architecture pays homage to its Dalmatian roots
                  while the selective interior design keeps it modern with high
                  quality furnishing and a few quirky vintage pieces.
                </P>
                <P color="rgb(123,116,110)" align="right">
                  The perfect spot for families or couples, every small touch
                  has been thought of to ensure a maximally comfortable stay for
                  every guest. All modern amenities are provided, including air
                  conditioning, a dishwasher, a washing machine, a fully
                  equipped kitchen, and a smart TV with Netflix.
                </P>
                <Btn
                  border="1px solid #f5a913"
                  color="#7b746e"
                  width="358px"
                  hbgcolor="#f5a913"
                  hcolor="#fff"
                  onClick={() => redirect("/villa")}
                >
                  <span>DISCOVER THE VILLA</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content
              style={{
                backgroundImage: `url(${island})`,
                backgroundSize: "cover",
                backgroundPosition: "30%",
              }}
            >
              <Col align="start">
                <H2 align="left">
                  <span>WHERE IS IT?</span>
                  <Line align="start" />
                </H2>
                <H3 align="left">SECLUDED ; GETAWAY ; PARADISE</H3>
                <Col align="start">
                  <P color="rgb(123,116,110)" align="left">
                    Nestled in the secluded Medvidina Bay on the southern side
                    of Hvar island, down a 4-kilometre winding gravel road, you
                    will be greeted with La Villa Bleue, the gorgeous Adriatic
                    Sea... and not much else. You will find only a handful of
                    houses in the bay, and three pristine beaches.
                  </P>
                  <P color="rgb(123,116,110)" align="left">
                    This location is an experience in and of itself. Nature
                    completely envelopes you in the form of crystal clear,
                    turquoise waters softly lapping against the pale grey
                    pebbles of the oh-so-quiet beach, against the backdrop of
                    untamed agave plants and a chorus of cicadas.
                  </P>
                  <P color="rgb(123,116,110)" align="left">
                    The gravel road down to the bay starts with a turn off the
                    main road spanning the island, between the villages of Gdinj
                    and Zastražiš<span style={{ fontSize: "14px" }}>ć</span>e.
                    It is rocky in places and makes for a bumpy and sometimes
                    steep ride down to the house, but this gravel road serves to
                    ensure the beaches of Medvidina Bay stay quiet, even in the
                    peak of summer.
                  </P>
                  <P color="rgb(123,116,110)" align="left">
                    Only those who really want a slice of true, quiet paradise
                    will reach this stunning bay.
                  </P>
                </Col>
                <Btn
                  border="1px solid rgba(123, 116, 110, 0.78)"
                  bgcolor="rgba(123, 116, 110, 0.78)"
                  width="358px"
                  hborder="1px solid #f5a913"
                  hbgcolor="transparent"
                  hcolor="rgba(123, 116, 110, 0.78)"
                  onClick={() => redirect("/island")}
                >
                  <span>EXPLORE THE ISLAND</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section>
            <ImageGallery
              items={images}
              lazyLoad={true}
              showBullets={false}
              showNav={false}
              showPlayButton={false}
              autoPlay={true}
              showThumbnails={false}
              showFullscreenButton={breakpoints.sm ? false : true}
            />
          </Section>
          <Section>
            <Content style={{ paddingBottom: "0px" }}>
              <Col align="end">
                <H2 align="right">
                  <span>WHAT TO DO?</span>
                  <Line align="end" />
                </H2>
                <H3 align="right">RELAX ; EXPLORE ; ENJOY</H3>
                <Col align="end">
                  <P color="rgb(123,116,110)" align="right">
                    The villa is exceptionally well facilitated. Spend your
                    mornings enjoying freshly-ground coffee on the terrace
                    overlooking the sea before picking up your complementary
                    beach towel and relaxing on the beach.{" "}
                    <strong>Want to explore the surrounding bays?</strong> You
                    have the option of two SUP boards or a 3-person hard kayak –
                    both of which are free for you to use.{" "}
                    <strong>
                      Want to explore the Adriatic a little further?
                    </strong>{" "}
                    You can rent the small 4-person, 6HP boat in the bay – or
                    contact us to arrange larger boat rentals
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    <strong>Want to keep active?</strong> We can organise for
                    private yoga, kickboxing, or personal training sessions with
                    our qualified instructors – all on the villa's beachside
                    terrace.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    <strong>Don't fancy cooking?</strong> There is an
                    exceptional restaurant with breathtaking sunset views just
                    in the nearby village of Zastražiš
                    <span style={{ fontSize: "14px" }}>ć</span>e, only a
                    15-minute drive away. We also have plenty of other
                    restaurant recommendations for you – some slightly closer to
                    home in nearby bays and villages – and also out to the towns
                    of Jelsa, Stari Grad, Vrboska... and in the lively Hvar town
                    itself!
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    <strong>Don't fancy driving?</strong> Enjoy a fantastic
                    Dalmatian dinner literally next door. Your neighbour, Antun
                    the fisherman (one of the few other homeowners in the bay),
                    runs a small konoba (restaurant) on his sea view terrace. So
                    just a few steps away, you can enjoy sublime local fish that
                    Antun has caught that day, paired with his homemade wine and
                    cold-pressed olive oil. Meat dishes and vegetarian dishes
                    are also available upon request.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    <strong>Feeling more adventurous?</strong> During your stay
                    at the villa, we will help you to arrange any day trips,
                    excursions, and transport as required. We have trusted,
                    reputable partners for for scuba diving, boat trips, island
                    tours, water sports, and so on.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    <strong>And when the kids have gone to bed?</strong>{" "}
                    Introducing Bar School. The legendary bar guru Chris
                    Edwardes lives on the island and is offering exclusive
                    cocktail masterclasses to our guests. Enjoy a paradisiac
                    evening under La Villa Bleue's bamboo beach terrace – Chris
                    not only brings all the alcohol but shows you how to make
                    all your favourite cocktails, and how to put a spin on them
                    like an absolute boss. The price includes all alcohol,
                    fruit, and ice – and pdfs to take with you so you can
                    replicate the magic back at home!
                  </P>
                </Col>
                <Btn
                  border="1px solid #f5a913"
                  color="#7b746e"
                  width="358px"
                  hbgcolor="#f5a913"
                  hcolor="#fff"
                  style={{ marginTop: "1.5rem" }}
                  onClick={() => redirect("/amenities")}
                >
                  <span>ALL AMENITIES</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section>
            <Content>
              <H2
                align="center"
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                <span>WHAT GUESTS SAY</span>
                <Line align="center" style={{ margin: "10px auto 23px" }} />
              </H2>
              <Helmet>
                <script src="https://apps.elfsight.com/p/platform.js" defer />
              </Helmet>

              <div className="elfsight-app-524d14b9-e4fc-4f93-98dd-5b85ce92cd37"></div>
            </Content>
          </Section>
          <Section>
            <Grid2>
              <div style={{ maxWidth: "100%" }}>
                <img src={blue40} style={{ width: "100%", height: "100%" }} />
              </div>
              <GridWrapper style={{ backgroundColor: "rgb(238,236,234)" }}>
                <H2 align="right">
                  <span>TRUE SUSTAINABILITY</span>
                  <Line align="end" />
                </H2>
                <H3 align="right">
                  REDEFINING <br />
                  OFF-GRID
                </H3>
                <Col align="end">
                  <P color="rgb(123,116,110)" align="right">
                    Sustainability is now more important than ever. La Villa
                    Bleue is a truly off-grid home, 100% powered by solar. We
                    reimagined what off-grid can look like, so welcome our
                    guests to (mindfully) use all of the modern amenities (AC,
                    washing machine, dishwasher) for a maximally enjoyable stay.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    This is back to nature, but at its most comfortable.
                  </P>
                </Col>
              </GridWrapper>
              <GridWrapper style={{ backgroundColor: "rgb(250, 250, 250)" }}>
                <H2 align="left">
                  <span>BECAUSE TRAVEL IS</span>
                  <Line align="start" />
                </H2>
                <H3 align="left">
                  A SENSORY
                  <br />
                  EXPERIENCE
                </H3>
                <Col align="start">
                  <P color="rgb(123,116,110)" align="left">
                    Medvidina Bay is serene and tranquil, a true hidden gem on
                    Croatia's premium island offering an experience like no
                    other – pure peace and quiet, with the time and space to
                    bask on the beach in the sun’s rays and to soak up
                    breathtaking views.
                  </P>
                  <P color="rgb(123,116,110)" align="left">
                    Combine this remote, secluded location with the comfort of
                    300-thread count bed linens, luxury towels, high-quality
                    toiletries, and a dedicated concierge service – and you've
                    just about found paradise.
                  </P>
                </Col>
              </GridWrapper>
              <div style={{ maxWidth: "100%" }}>
                <img src={beach4} style={{ width: "100%", height: "100%" }} />
              </div>
            </Grid2>
          </Section>
          <Footer type="full" />
        </>
      ) : (
        <Menu setOpen={setOpen} />
      )}
    </Layout>
  );
}
